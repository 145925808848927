import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import FlexibleIcon from "../images/flexible.svg"
import IntuitiveIcon from "../images/inituitive.svg"
import SecureIcon from "../images/securityWebsite1.svg"
import IntegrationBerry from "../images/berry_integration.svg"
import MaintanceBerry from "../images/berry_maintance.svg"
// import BerryIcon from "../images/greenberry.svg"
import {ButtonGf, ButtonLink, ButtonWrapper, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark, IoSpeedometer} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import {BannerSection, BenefitsSection, PracticesSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import {LaunchAppSection} from "../components/ApplicationPage/styles";
import { BannerStyles} from "../const/inline_styles";
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper";
import {TheBerryVideoSection, VideoBlock} from "../components/BerryPage/styles";
import ImageAccordion from "../components/ImageAccordion";
import AnalysisIconSmall from "../images/analyisis_small_icon.svg";
import AnalysisIconBig from "../images/analysis_icon.svg";
import AdjustmentIconSmall from "../images/adjustment_small_icon.svg"
import AdjustmentIconBig from "../images/adjustment_icon.svg"
import IntegrationIconSmall from "../images/integration_small_icon.svg"
import IntegrationIconBig from "../images/integration_icon.svg"
import ClientControllIconSmall from "../images/clientcontrol_small_icon.svg"
import ClientControllIconBig from "../images/clientcontrol_icon.svg"
import ResultIconSmall from "../images/competitiveness.svg"
import ResultIconBig from "../images/cms-result.svg"
import PromoIcon from "../images/promo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GreenBerry from "../images/berrySection.svg"

import localData from "../localization/cms-the-berry.json"


const accordionItems = [
    {
        id:1,
        title: {
            en: "Analysis",
            es: "Análisis"
        },
        content: {
            en: "We start by examining your existing website and proceed to decide what sections and pages should be controlled by you.",
            es: "Comenzamos examinando tu sitio web existente y procedemos a decidir cuáles son las secciones y páginas que deben ser controladas por tu parte."
        },
        icon: {
            name: AnalysisIconSmall
        },
        image: AnalysisIconBig,
        open: true,
    },
    {
        id:2,
        title: {
            en: "Design",
            es: "Diseño"
        },
        content: {
            en: "We make the necessary adjustments and create personalized and custom-designed templates for your company.",
            es: "Realizamos los ajustes necesarios y creamos las plantillas personalizadas y diseñadas a medida para su empresa"
        },
        icon: {
            name: AdjustmentIconSmall,
            size: "24px"
        },
        image: AdjustmentIconBig,
        open: false,
    },
    {
        id:3,
        title: {
            en: "Integration",
            es: "Integración"
        },
        content: {
            en: "We host your new website on a highly optimized and dedicated server and integrate it with the \"The Berry\" platform.",
            es: "Alojamos tu nuevo sitio web en un servidor altamente optimizado y dedicado y lo conectamos\n" +
                "con la plataforma “The Berry”.\n"
        },
        icon: {
            name: IntegrationIconSmall,
            size: "24px"
        },
        image: IntegrationIconBig,
        open: false,
    },
    {
        id:4,
        title: {
            en: "Handover",
            es: "Entrega"
        },
        content: {
            en: "We present to you the platform functionalities and provide you and your team with training\n" +
                "so that you can get the most out of “The Berry” integration.\n",
            es: "Te presentamos todas las funcionalidades de la plataforma y le proporcionamos formación\n" +
                "para que el equipo de tu empresa pueda sacarle el máximo beneficio.\n"
        },
        icon: {
            name: ClientControllIconSmall,
            size: "24px"
        },
        image: ClientControllIconBig,
        open: false,
    },
    {
        id:5,
        title: {
            en: "Result",
            es: "Resultado"
        },
        content: {
            en: "You and your team control and manage the content of your highly optimized website without the need of hiring a developer. As simple as saying WOW.",
            es: "Te presentamos todas las funcionalidades de la plataforma y le proporcionamos formación\n" +
                "para que el equipo de tu empresa pueda sacarle el máximo beneficio.\n"
        },
        icon: {
            name: ResultIconSmall,
            size: "24px"
        },
        image: ResultIconBig,
        open: false,
    }
]
// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]
const GFCmsTheBerry = ({data, location, pageContext}) => {

    const lang = pageContext.lang
    const slides = []
    data.allFile.nodes.forEach(img => {
        slides.push({ url: img.childImageSharp.fluid.src})
    })
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
  return <Layout location={location} lang={lang} flags={flags}>
            <SEO
                title={ meta?.metaTitle || metaStatic.metaTitle}
                description={ meta?.metaDescription || metaStatic.metaDescription}
                keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
            />
      <BannerSection>
          <Container>
              <div className="berry-cms-banner-section" style={{display:"flex"}}>
                  <div>
                      <h2 dangerouslySetInnerHTML={{__html: localData.pageTitle[lang]}} style={BannerStyles.h2} />
                      <SectionTitle>
                          {localData.sections.hero.title[lang]}
                      </SectionTitle>
                      <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
                      <div className="info-box-list">
                          <InfoBox step={1} icon={<img src={FlexibleIcon} alt="flexible" />} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                          <InfoBox step={2} size="27px" icon={<img src={IntuitiveIcon} alt="customized" />} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                          <InfoBox size="25px" step={3} icon={<img src={SecureIcon} alt="optimized" />} text={localData.sections.hero.group.third[lang]} color={colors.grayColor} />
                      </div>
                      <div className="free-consultation">
                          <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                      </div>
                  </div>
                  <div>
                      <img src={GreenBerry} alt="berry logo icon"/>
                  </div>
              </div>
          </Container>
          {/*<div className="bg-image">*/}
          {/*    <img src={CirclesFour} alt="circles"/>*/}
          {/*</div>*/}
      </BannerSection>
      <BenefitsSection>
          <Container>
              <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
              <div className="content">
                  <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                  <ul>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.first[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.second[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.third[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fourth[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fifth[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.sixth[lang]}} /></li>
                  </ul>
                  <div className="get-in-touch">
                      <h4>{localData.sections.benefits.subTitle[lang]}</h4>
                      <span>{localData.sections.benefits.subDescription[lang]}</span>
                      {/*<ButtonGf>Start</ButtonGf>*/}
                      <div className="free-consultation free-consultation--flex">
                          <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                      </div>
                  </div>
              </div>
          </Container>
          <img className="left" src={CircleTwo} alt="circles two"/>
          <img className="right" src={CircleThree} alt="circles three"/>
      </BenefitsSection>
      <TheBerryVideoSection>
            <Container>
                <SectionTitle align="center">{localData.sections.discover.title[lang]}</SectionTitle>
                <VideoBlock>
                    <Carousel showStatus={false} showThumbs={false}>
                        {slides.map((slide, index) => {
                            return <div key={index}>
                                <img src={slide.url} alt="pic"/>
                            </div>
                        })}
                    </Carousel>
                </VideoBlock>
                <ButtonWrapper align="center">
                    <ButtonLink width="auto" to={localData.sections.discover.button.uri[lang]} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{localData.sections.discover.button.label[lang]}</ButtonLink>
                </ButtonWrapper>
            </Container>
      </TheBerryVideoSection>
      <PracticesSection>
          <Container>
              <SectionTitle align="center">{localData.sections.works.title[lang]}</SectionTitle>
              {/*<p style={{textAlign:"center", maxWidth:"900px", marginRight:"auto", marginLeft:"auto"}}>“The Berry” is not like the other website builders that have hidden fees and functionality constraints.*/}
              {/*    With this Content Management System you get everything you require at one place.*/}
              {/*    Oh and yes, hosting and https are FREE.</p>*/}
              <ImageAccordion lang={lang} accordionItems={accordionItems} />
          </Container>
      </PracticesSection>
      <LaunchAppSection>
          <Container>
              <SectionTitle align="center">{localData.sections.connect.title[lang]}</SectionTitle>
              {/*<p style={{textAlign:"center"}}>We offer different types of payment models to suit your requirements</p>*/}
              <div className="box-wrapper">
                  <div className="box">
                      <AnimatedInViewWrapper
                          styles={{
                              width:"70px"
                          }}
                      initial="hidden"
                      variants={
                          {
                              visible: {
                                  y:0,
                                  opacity:1,
                                  transition: {
                                      duration:.6
                                  }
                              },
                              hidden: {
                                  y:"20px",
                                  opacity:0,
                                  transition: {
                                      duration:.6
                                  }
                              }
                          }
                      }
                      >
                          <img style={{width:"100%"}} src={IntegrationBerry} alt=""/>
                      </AnimatedInViewWrapper>
                      <span>{localData.sections.connect.group.second.title[lang]}</span>
                      <p>{localData.sections.connect.group.second.description[lang]}</p>
                      <span style={{marginBottom:"10px", fontWeight:"300"}}>{localData.sections.connect.group.second.cost[lang]}</span>
                      <a style={{color:colors.lightGrey, textDecoration:"none", fontSize:"16px"}} href="#">{localData.sections.connect.group.second.quote[lang]}</a>
                  </div>
                  <div className="box">
                      <div className="promo">
                          <img src={PromoIcon} alt="promo icon"/>
                      </div>
                      <AnimatedInViewWrapper styles={{
                          width:"70px"
                      }}
                                             initial="hidden"
                                             variants={
                                                 {
                                                     visible: {
                                                         y:0,
                                                         opacity:1,
                                                         transition: {
                                                             duration:.6,
                                                             delay:.4
                                                         }
                                                     },
                                                     hidden: {
                                                         y:"20px",
                                                         opacity:0,
                                                         transition: {
                                                             duration:.6
                                                         }
                                                     }
                                                 }
                                             }>
                          <img style={{width:"100%"}} src={MaintanceBerry} alt=""/>
                      </AnimatedInViewWrapper>

                      <span>{localData.sections.connect.group.first.title[lang]}</span>
                      <p>{localData.sections.connect.group.first.description[lang]}</p>
                      <div style={{marginBottom:"10px", fontWeight:"300", height:"23px"}}>{localData.sections.connect.group.first.cost[lang]} <span style={{textDecoration:"line-through", fontWeight:"300"}}>30€</span><strong style={{color:colors.greenColor, fontWeight:"bold"}}> 0€</strong></div>
                      <span style={{color:colors.lightGrey, fontWeight:"300", fontSize:"16px"}}>{localData.sections.connect.group.first.promotion[lang]}</span>
                  </div>
              </div>
          </Container>
      </LaunchAppSection>
  </Layout>
}

export const query = graphql`
    {
        allFile(filter: {relativeDirectory: {eq: "gallery/berry"}}) {
            nodes {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
`


export default GFCmsTheBerry
