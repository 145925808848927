import styled from "styled-components"
import { SectionWrapper} from "../WebsitePage/styles";
import {colors} from "../../const/colors";

export const DevelopmentSection = styled(SectionWrapper)`
  background-color: ${colors.lightBg};
  .content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    max-width:900px;
    margin: 50px auto;
  }
  .main-content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    margin:50px auto;
    width:100%;
  }
  p {
    text-align: center;
  }
  .img-block {
    width:100%;
    img {
      display: block;
      margin:auto;
      max-width:350px;
    }
    @media screen and (min-width: 768px){
      width:49%;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width:100%;
    margin:auto;
    padding:0 20px;
    list-style-type: none;
    @media screen and (min-width: 768px){
      width:49%;
    }
  }
  li {
    width:100%;
    display:flex;
    align-items: center;
    svg {
      margin-right:10px;
    }
    @media screen and (min-width: 400px) {
      width:48%;
      justify-content: center;
    }
    @media screen and (min-width: 600px) {
      width:33%;
    }
    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
  }
`

export const SupportSection = styled(SectionWrapper)`
    .content {
      max-width:900px;
      margin:50px auto;
      text-align: center;
    }
  p {
    @media screen and (min-width: 400px){
      width:80%;
      margin:auto;
    }
  }
    .img-block {
      position:relative;
      left:0px;
      @media screen and (min-width: 750px){
        left:${props => props.imageLeftPosition ? props.imageLeftPosition : "0px"};
      }
      margin:80px auto 50px auto;
      max-width:600px;
      width:100%;
      @media screen and (min-width: 768px){
        width:80%;
      }
    }
  span {
    color:${colors.grayColor};
    font-weight: bold;
    display: inline-block;
    margin-bottom:30px;
  }
`
export const LaunchAppSection = styled(SectionWrapper)`
  background-color:${colors.lightBg};
  padding-bottom:50px;
  .box-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap:wrap;
    margin:50px auto;
  }
  .box {
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    background:white;
    border-radius: 5px;
    margin-bottom:30px;
    padding:55px;
    text-align: center;
    .promo {
      width:15%;
      max-width:125px;
      min-width:85px;
      position:absolute;
      right:0;
      top:0;
      img {
        display:block;
        max-width:100% !important;;
        width:100%;
      }
    }
    @media screen and (min-width: 768px){
      width:49%;
    }
    //img {
    //  max-width:45px;
    //}
    span {
      display: inline-block;
      font-weight: bold;
      color:${colors.grayColor};
      margin-bottom:25px;
    }
    p {
      max-width:450px;
    }
  }
  .button-wrapper {
    width:100%;
    display: flex;
    justify-content: center;
  }
`